import * as selfserveActionTypes from '../actionTypes/selfserveActionTypes';
import { GetSlotAvailabilityData } from '../../api/selfserve';
import { HOMECARE_PLAN_LIST } from '../../constants/index';
import { generateStaticDates } from '../../utils/selfserve';
import { cleverTapTrigger } from '../../tracking/segment';

export const updateMobileNumber = (data: string) => {
    return dispatch => {
        dispatch({
            type: selfserveActionTypes.UPDATE_MOBILE_NUMBER,
            data: data,
        })
    }
}

export const updateWhatsappCheck = (data: boolean) => {
    return dispatch => {
        dispatch({
            type: selfserveActionTypes.UPDATE_WHATSAPP_CHECK,
            data: data,
        })
    }
}

export const changeProgress = (data: number, force=false) => {
    return dispatch => {
        dispatch({
            type: selfserveActionTypes.UPDATE_CURRENT_STEP,
            data,
            force,
        })
    }
}

export const changeEdit = (data: boolean) => {
  return dispatch => {
      dispatch({
          type: selfserveActionTypes.UPDATE_EDIT_MODE,
          data,
      })
  }
}

export const previousStepProgress = (currentTicketKey : string | null = null) => {
    return dispatch => {
        dispatch({
            type: selfserveActionTypes.UPDATE_PREVIOUS_STEP,
            key:currentTicketKey,
        })
    }
}

export const changeTrackerState = (data: number) => {
  return (dispatch) => {
    dispatch({
      type: selfserveActionTypes.UPDATE_TRACKER_STEP,
      data: data,
    });
  };
};

export const changeIssuePageFrom = (data: number) => {
  return (dispatch) => {
    dispatch({
      type: selfserveActionTypes.UPDATE_ISSUE_PAGE_FROM,
      data: data,
    });
  };
};

export const changeLandedOnPage = (data: any) => {
  return (dispatch) => {
    dispatch({
      type: selfserveActionTypes.UPDATE_LANDED_ON_PAGE,
      data: data,
    });
  };
};

export const changeAddressPageFrom = (data: number) => {
  return (dispatch) => {
    dispatch({
      type: selfserveActionTypes.UPDATE_ADDRESS_PAGE_FROM,
      data: data,
    });
  };
};

export const updateTokenId = (data: any) => {
  return (dispatch) => {
    dispatch({
      type: selfserveActionTypes.UPDATE_TOKEN_ID,
      data: data,
    });
  };
};

export const updateUserPlans = (data: []) => {
  return (dispatch) => {
    dispatch({
      type: selfserveActionTypes.UDPATE_USER_PLANS,
      data: data,
    });
  };
};

export const updateSelectedPlan = (data = {}) => {
  return dispatch => {
      dispatch({
          type: selfserveActionTypes.UPDATE_SELECTED_PLAN,
          data: data,
      })
  }
}

export const planCoversFetched = (data: any) => {
  return (dispatch) => {
    dispatch({
      type: selfserveActionTypes.PLAN_COVERS_FETCHED,
      data: data,
    });
  };
};

export const planIssuesFetched = (data: any) => {
  return dispatch => {
      dispatch({
          type: selfserveActionTypes.UPDATE_ISSUES_FETCHED,
          data: data,
      })
  }
}

export const updateSelectedCover = (data: any) => {
  return (dispatch) => {
    dispatch({
      type: selfserveActionTypes.UPDATE_SELECTED_COVER,
      data: data,
    });
  };
};

export const removeSelectedCover = (data: any) => {
  return (dispatch) => {
    dispatch({
      type: selfserveActionTypes.REMOVE_SELECTED_COVER,
      data: data,
    });
  };
};

export const updateCustomIssue = (data: any) => {
  return (dispatch) => {
    dispatch({
      type: selfserveActionTypes.UPDATE_CUSTOM_ISSUE,
      data: data,
    });
  };
};

export const updateSelectedProblems = (data: any) => {
  return (dispatch) => {
    dispatch({
      type: selfserveActionTypes.UPDATE_SELECTED_PROBLEMS,
      data: data,
    });
  };
};

export const updateSrReopenReason = (data: object) => {
  return (dispatch) => {
    dispatch({
      type: selfserveActionTypes.UPDATE_SR_REOPEN_REASON,
      data: data,
    });
  };
};

export const updateAddressDetails = (data: object) => {
  return (dispatch) => {
    dispatch({
      type: selfserveActionTypes.UPDATE_ADDRESS_DETAILS,
      data: data,
    });
  };
};

export const updateCityDetails = (data: object) => {
  return (dispatch) => {
    dispatch({
      type: selfserveActionTypes.UPDATE_CITY_DETAILS,
      data: data,
    });
  };
};


const formatSlotData = (res) => {
    return Object.entries(res).reduce(
      (allSlots, slot: any) => {
        allSlots.slots[slot[0]] = {
          timings: slot[1],
          isTimeAvailable: slot[1].some((timings) => timings.is_available)
            ? true
            : false,
        };
        if (allSlots.slots[slot[0]]["isAvailable"]) {
          allSlots.isDateAvailable = false;
        }
        return allSlots;
      },
      { isDateAvailable: true, slots: {} }
    );
  };

  export const updateProcessType = (data: object) => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({
            type: selfserveActionTypes.UPDATE_PROCESS_TYPE,
            data: data,
        })
        if (data['process_type'] === 'AMC' && !HOMECARE_PLAN_LIST.includes(state.selfserveReducer.parentPlanDetails.plan_name)) {
            let selectedCover = state.selfserveReducer.serviceRequests[0].selected_cover.cover_details[0]
            let deviceDetail = state.selfserveReducer.serviceRequests[0].device_detail
            let parent_sr_ref_no = selectedCover.reopen ? JSON.stringify(selectedCover.sr_id) : null
            let brand_list = deviceDetail.brand ? [deviceDetail.brand] : []
            let d = Object()
            d[selectedCover.cover_title] = 1
            const data = {
                'category': JSON.stringify(deviceDetail.category_type),
                'brand_list': JSON.stringify(brand_list),
                'pincode': JSON.stringify(state.selfserveReducer.customerDetails.address.pincode),
                'application': 'website',
                'cover_names': JSON.stringify(d),
                'parent_sr_ref_no': parent_sr_ref_no,
                'sub_application': 'selfserve',
                'osid': JSON.stringify(state.selfserveReducer.planDetails.osid)
            }
            GetSlotAvailabilityData(data).then((res: any) => {
                if(res?.response.ok){
                    dispatch({
                        type: selfserveActionTypes.UPDATE_SLOT_AVAILABILITY_DATA,
                        data: res.data
                    })
                    dispatch({
                      type: selfserveActionTypes.ADD_SR_SLOT_SELECTION_DATA,
                      data: {...formatSlotData(res.data), items: [{category_type: data.category}]},
                    });
                }
                else{
                    dispatch({
                        type: selfserveActionTypes.REMOVE_SLOT_AVAILABILITY_DATA,
                        data: null
                    })
                    if(res?.data?.error=="SLOT_NOT_FOUND"){
                        cleverTapTrigger('Slot Selection - No Slot Available', {'source':'selfserve', 'category_name':deviceDetail.category_type, 'pincode':state.selfserveReducer.planDetails.order.address.pincode, 'category': state.selfserveReducer?.planDetails?.category, 'product_type': state.selfserveReducer?.planDetails?.product_type})
                        dispatch({
                          type: selfserveActionTypes.ADD_SR_SLOT_SELECTION_DATA,
                          data: {slots: {}, isDateAvailable: false, items: [{category_type: data.category}]},
                        });
                    } else{
                      dispatch({
                        type: selfserveActionTypes.ADD_SR_SLOT_SELECTION_DATA,
                        data: {slots: generateStaticDates(7), isDateAvailable: true, items: [{category_type: data.category}]},
                      });
                    }
                }
            }, (err) => {
                dispatch({
                    type: selfserveActionTypes.REMOVE_SLOT_AVAILABILITY_DATA,
                    data: err?.data ? err.data : null
                })
                if(err?.data?.error=="SLOT_NOT_FOUND"){
                    cleverTapTrigger('Slot Selection - No Slot Available',{'source':'selfserve', 'category_name':deviceDetail.category_type,'pincode':state.selfserveReducer.planDetails.order.address.pincode, 'category': state.selfserveReducer?.planDetails?.category, 'product_type': state.selfserveReducer?.planDetails?.product_type})
                    dispatch({
                      type: selfserveActionTypes.ADD_SR_SLOT_SELECTION_DATA,
                      data: {slots: {}, isDateAvailable: false, items: [{category_type: data.category}]},
                    });
                } else{
                  dispatch({
                    type: selfserveActionTypes.ADD_SR_SLOT_SELECTION_DATA,
                    data: {slots: generateStaticDates(7), isDateAvailable: true, items: [{category_type: ''}]},
                  });
                }
            })
        }
        else if(state.selfserveReducer.slotAvailabilityData?.maxDateOne) {
            dispatch({
                type: selfserveActionTypes.REMOVE_SLOT_AVAILABILITY_DATA,
                data: null
            })
            dispatch({
              type: selfserveActionTypes.ADD_SR_SLOT_SELECTION_DATA,
              data: {slots: generateStaticDates(30), isDateAvailable: true, items: [{category_type: ''}]},
            });
        }
    }
}

export const updateSrCapacityData = (data: object) => {
  return (dispatch) => {
    dispatch({
      type: selfserveActionTypes.UPDATE_SR_CAPACITY_DATA,
      data: data,
    });
  };
};

export const updateAppointmentDate = (data: object) => {
  return (dispatch) => {
    dispatch({
      type: selfserveActionTypes.UPDATE_APPOINTMENT_DATE,
      data: data,
    });
  };
};

export const updateTempSrId = (data: object) => {
  return (dispatch) => {
    dispatch({
      type: selfserveActionTypes.UPDATE_TEMP_SR_ID,
      data: data,
    });
  };
};

export const updateSuccessData = (data: object) => {
  return (dispatch) => {
    dispatch({
      type: selfserveActionTypes.UPDATE_SUCCESS_DATA,
      data: data,
    });
  };
};

export const updateFailureData = (data: object) => {
  return (dispatch) => {
    dispatch({
      type: selfserveActionTypes.UPDATE_FAILURE_DATA,
      data: data,
    });
  };
};

export const updateSrCreationSource = (data: object) => {
  return (dispatch) => {
    dispatch({
      type: selfserveActionTypes.UPDATE_SR_CREATION_SOURCE,
      data: data,
    });
  };
};


export const updateUrlLanded = (data: boolean) => {
  return dispatch => {
      dispatch({
          type: selfserveActionTypes.URL_LANDED,
          data: data,
      })
  }
}

export const pushToTicketList = () => {
return dispatch => {
  dispatch({
    type: selfserveActionTypes.PUSH_TO_TICKET_LIST,
  })
}
}

export const restoreFromTicketList = (key: string) => {
return dispatch => {
  dispatch({
    type: selfserveActionTypes.RESTORE_FROM_TICKET_LIST,
    key: key
  })
}
}

export const updateMultipleEnabled = (data: boolean) => {
  return dispatch => {
      dispatch({
          type: selfserveActionTypes.UPDATE_MULTIPLE_ENABLED,
          data: data,
      })
  }
}

export const updateCategoryDetails = (data: any) => {
  return dispatch => {
      dispatch({
          type: selfserveActionTypes.UPDATE_CATEGORY_DETAILS,
          data: data,
      })
  }
}

export const updateDeviceDetails = (data: any, forceKeygen:boolean=false,cleanServiceRequests:boolean=false) => {
  return dispatch => {
      dispatch({
          type: selfserveActionTypes.UPDATE_DEVICE_DETAILS,
          data: data,
          forceKeygen,
          cleanServiceRequests,
      })
  }
}

export const updateParentDeviceDetails = (data = {}) => {
  return dispatch => {
      dispatch({
          type: selfserveActionTypes.UPDATE_PARENT_DEVICE_DETAILS,
          data: data,
      })
  }
}
export const updateEditScreenDetails = (data :number | string, key :any=null) => {
  return dispatch => {
      dispatch({
          type: selfserveActionTypes.UPDATE_EDIT_SCREEN_DETAILS,
          data: data,
          key: key,
      })
  }
}

export const updateIsServicePgChargesApplicable = (data: boolean) => {
  return dispatch => {
    dispatch({
      type: selfserveActionTypes.UPDATE_IS_SERVICE_PG_CHARGES_APPLICABLE,
      data: data,
    })
  }
}

export const removeAddDevice = (key: string) => {
  return dispatch => {
      dispatch({
          type: selfserveActionTypes.REMOVE_ADDED_DEVICES,
          key: key,
      })
  }
}

export const resetSelectedProblems = () => {
return dispatch => {
    dispatch({
        type: selfserveActionTypes.REMOVE_SELECTED_PROBLEMS,
    })
}
}

export const updateCopaymentdetails = (data: any) => {
  return dispatch => {
      dispatch({
          type: selfserveActionTypes.UPDATE_COPAYMENT_DETAILS,
          data: data
      })
  }
}


export const canUploadDocuments = (data: boolean) => {
  return (dispatch) => {
      dispatch({
          type: selfserveActionTypes.CAN_UPLOAD_DOCUMENTS,
          data:data
      });
  };
};

export const uploadedFilesRedux = (data: object) => {
  return (dispatch) => {
    dispatch({
      type: selfserveActionTypes.UPLOADED_DOCUMENTS,
      data: data,
    });
  };
};

export const deleteUploadedFileRedux = (data: string) => {
  return (dispatch) => {
    dispatch({
      type: selfserveActionTypes.DELETE_UPLOADED_DOCUMENT,
      data: data
    })
  }
}

export const resetUploadedDocumentRedux = () => {
  return (dispatch) => {
    dispatch({
      type: selfserveActionTypes.RESET_UPLOADED_DOCUMENT,
    })
  }
}

export const additionComments = (data: any) => {
  return (dispatch) => {
    dispatch({
      type: selfserveActionTypes.COMMENTS,
      data: data,
    });
  };
};

